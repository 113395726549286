import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import styles from './index.module.scss'
import Layout from 'components/layout'
import SEO from 'components/seo'
import intl from '@/intl'
import toTitleCase from 'titlecase'
import { keys, pickBy } from 'lodash'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import Sticky from 'components/react-stickynode'

const LegalsPage = (props) => {
  const lang = props.pageContext.code
  const title = props.data.allContentfulLegals.nodes[0].title
  const body = props.data.allContentfulLegals.nodes[0].childContentfulLegalsBodyRichTextNode.json
  const Text = ({ children }) => <p className={`mb-4 lg:mb-8`}>{children}</p>
  const Quote = ({ children }) => <div className={`lg:mb-8 uppercase text-base`}>{children}</div>
  const HyperLink = ({ children, to }) => <a href={to} className={`border-solid border-b border-black pb-xs`}>{children}</a>
  const options = {
    renderNode: {
      [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [INLINES.HYPERLINK]: (node, children) => <HyperLink to={node.data.uri}>{children}</HyperLink>
    },
    renderText: text => text.replace('!', '?'),
  }
  const pad = `p-4`
  const indexes = keys(pickBy(body.content, { nodeType: `blockquote` }))
  const allContent = indexes.map((index, i) => {
    const content = body.content
    const start = parseInt(index) + 1
    const end = indexes[i+1] ? indexes[i+1] : undefined
    return {
      title: {
        nodeType: `document`,
        data: {},
        content: [content[parseInt(index)]],
      },
      body: {
        nodeType: `document`,
        data: {},
        content: content.slice(start, end)
      }
    }
  })
  .map((section, i) => {
    const title = documentToReactComponents(section.title, options)
    const body = documentToReactComponents(section.body, options)
    return (
      <div key={`section-${section.title}-desktop-${i}`} className={`lg:flex`}>
        <div className={`hidden lg:flex`}>
          <div className={`lg:w-4/7 lg:flex-auto upppercase ${pad} p-4 pl-logo`}>{title}</div>
          <div className={`lg:w-3/7 lg:flex-auto ${pad} p-4 pl-logo`}>{body}</div>
        </div>
        <div className={`block lg:hidden`}>
          <div className={`uppercase ${pad} p-4 pl-logo`}>{title}</div>
          <div className={`lg:w-3/7 lg:flex-auto ${pad} p-4 pl-logo pt-0 pb-0`}>
            <div>{body}</div>
          </div>
        </div>
      </div>
    )
  })
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>
      <SEO
        title={toTitleCase(intl(`legals`))}
        lang={props.pageContext.code}
        pathname={props.location.pathname}
      />
      <div className={styles.parent}>
        <div className={`hidden lg:block`}>
          <section className={`w-3/7 fixed right-0 text-base`}>
            <h1 className={`uppercase ${pad} p-4 pl-logo pt-4`}>{title}</h1>
          </section>
          <div className={`pt-32`}>{allContent}</div>
        </div>
        <div className={`block lg:hidden`}>
          <Sticky
            enabled={true}
            bottomBoundary='#all-content'
            innerZ={2}
          >
            <Link className={`absolute z-20 p-4 pl-logo`} to={`/${lang}`}>
              <img alt={`Pierre Augustin Rose`} src={`/logo.svg`} />
            </Link>
          </Sticky>
          <div className={`h-32`}/>
          <h1 className={`px-4 uppercase text-base p-4 pl-logo`}>{intl('legals')}</h1>
          <section className={`w-full pt-20 wrap`}>
            <div id={`all-content`} className={`h-1`}></div>
            <div className={``}>{allContent}</div>
          </section>
        </div>
        <div className={`h-12`}></div>
      </div>
    </Layout>
  )
}

export const LegalsQuery = graphql`
  query LegalsQuery($code: String) {
    allContentfulLegals(filter: {node_locale: {eq: $code}}) {
      nodes {
        title
        childContentfulLegalsBodyRichTextNode {
          json
        }
      }
    }
  }
`

export default LegalsPage
